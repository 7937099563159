import Header from './components/Header.js'
import Main from './components/Main.js'
import Footer from './components/Footer.js'

function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  )
}

export default App;
